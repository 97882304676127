import './App.css';
import {VerifyCertificate} from "./components/VerifyCertificate";
import 'bootstrap/dist/css/bootstrap.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

function App() {
    return (
        <Router>
            <div className="App">
                <Switch>
                    <Route exact path="/">
                        <VerifyCertificate/>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
