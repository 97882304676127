import axios from "axios";

const jsSigs = require('jsonld-signatures');
const {RSAKeyPair, Ed25519KeyPair} = require('crypto-ld');
const {contexts} = require('security-context');
const {CERTIFICATE_CONTROLLER_ID} = require("../config/config");
const {Ed25519Signature2018, RsaSignature2018} = jsSigs.suites;
const vc = require('vc-js');
const credentialsV1 = require('./credentialsv1.json');
const DEFAULT = "default";

const KeyType = {
    RSA: "RSA", ED25519: "ED25519"
};

export const verifyCredentials = async (signedCredentials, externalPublicKey = null) => {
    const signingKeyType = inferSignatureAlgoType(signedCredentials)
    let publicKeyStr = externalPublicKey;
    let {publicKey, verificationMethod} = await getKeys(signedCredentials.issuer);
    publicKeyStr = publicKeyStr ? publicKeyStr: publicKey;
    const publicKeyObj = getPublicKey(signingKeyType, publicKeyStr, verificationMethod);
    const controller = {
        '@context': jsSigs.SECURITY_CONTEXT_URL,
        id: verificationMethod,
        publicKey: [publicKeyObj],
        assertionMethod: [publicKeyObj.id]
    };
    switch (signingKeyType) {
        case KeyType.RSA:
            return await verifyRSACredentials(controller, signedCredentials, publicKeyStr, verificationMethod);
        case KeyType.ED25519:
            return await verifyED25519Credentials(controller, signedCredentials, publicKeyObj);
        default: return null;
    }
};

function inferSignatureAlgoType(signedCredentials) {
    switch (signedCredentials?.proof?.type) {
        case 'RsaSignature2018':
        case 'RsaVerificationKey2018':
            return KeyType.RSA;
        default:
            return KeyType.ED25519;
    }
}

async function getKeys(issuer) { //todo move this to a config file
    const publicKeysResponse = {
        data: {
            "issuers": {
                "did:taxid.gov.et": {
                    "publicKey": "E78oebMZC1yF45ThveVjUwMAkGzwf48CsiCxyaqXEx4u",
                    "signatureType": "ED25519",
                    "verificationMethod": "did:taxid.gov.et"
                }
            }
        }
    }
    let issuerKeyMap = publicKeysResponse.data;
    let key = (issuer in issuerKeyMap.issuers) ? issuer : DEFAULT;
    if (issuerKeyMap.issuers[key] !== undefined) {
        let {publicKey, privateKey, signatureType, verificationMethod} = issuerKeyMap.issuers[key];
        return {publicKey: publicKey, privateKey: privateKey, signatureType: signatureType, verificationMethod};
    }
    throw new Error("Invalid issuer");
}

function getPublicKey(signingKeyType, publicKey = null, issuerDid = null) {
    const keyType = signingKeyType === KeyType.ED25519 ? 'Ed25519VerificationKey2018' : 'RsaVerificationKey2018';
    let publicKeyObject = {
        '@context': jsSigs.SECURITY_CONTEXT_URL,
        id: issuerDid,
        type: keyType,
        controller: CERTIFICATE_CONTROLLER_ID,
    };
    let publicKeyPropertyKey = signingKeyType === KeyType.ED25519 ? 'publicKeyBase58' : 'publicKeyPem';
    publicKeyObject[publicKeyPropertyKey] = publicKey;
    return publicKeyObject;
}

const verifyRSACredentials = async (controller, signedCredentials, externalPublicKey, verificationMethod) => {
    const key = new RSAKeyPair({"id": verificationMethod, "publicKeyPem": externalPublicKey});
    const {AssertionProofPurpose} = jsSigs.purposes;
    return await jsSigs.verify(signedCredentials, {
        suite: new RsaSignature2018({key}),
        purpose: new AssertionProofPurpose({controller}),
        compactProof: false,
        documentLoader: await customLoader
    });
};

async function verifyED25519Credentials(controller, signedCredentials, publicKeyObject) {
    const key = new Ed25519KeyPair({...publicKeyObject});
    const {AssertionProofPurpose} = jsSigs.purposes;
    const purpose = new AssertionProofPurpose({
        controller: controller
    });
    return await vc.verifyCredential({
        credential: signedCredentials,
        suite: new Ed25519Signature2018({key}),
        purpose: purpose,
        documentLoader: await customLoader,
        compactProof: false
    });
}

let documentLoaderMapping = {"https://w3id.org/security/v1": contexts.get("https://w3id.org/security/v1")};
documentLoaderMapping['https://www.w3.org/2018/credentials#'] = credentialsV1;
documentLoaderMapping["https://www.w3.org/2018/credentials/v1"] = credentialsV1;

const customLoader = async url => {
    let context = documentLoaderMapping[url];
    if (context === undefined) {
        context = contexts[url];
    }
    if (context !== undefined) {
        return {
            contextUrl: null, documentUrl: url, document: context
        };
    }
    if (url.startsWith("{")) {
        return JSON.parse(url);
    }
    const urlObj = new URL(url);
    const contextResponse = await axios.get("/proxy" + urlObj.pathname, {
        headers: {
            target: urlObj.origin
        }
    });
    const loadedContext = contextResponse.data;
    documentLoaderMapping[url] = loadedContext;
    return {
        contextUrl: null, documentUrl: url, document: loadedContext
    }
};