import React, {Component} from 'react';
import './index.css';
import {scanImageData} from "zbar.wasm";

const SCAN_PERIOD_MS = 100;

function hasGetUserMedia() {
    return !!(
        (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) ||
        navigator['webkitGetUserMedia'] ||
        navigator['mozGetUserMedia'] ||
        navigator['msGetUserMedia']
    );
}

export default class QRScanner extends Component {
    static defaultProps = {
        className: '',
        height: 300,
        width: 400,
        videoConstraints: {
            facingMode: "environment"
        }
    };

    static mountedInstances = [];

    static userMediaRequested = false;

    static scanTimer = null;

    constructor(props) {
        super(props);
        this.state = {
            hasUserMedia: false,
        };
        this.initCanvas()
    }

    initCanvas(){
        this.canvas = document.createElement('canvas');
        this.canvas.width = this.props.width;
        this.canvas.height = this.props.height
        this.canvasCtx = this.canvas.getContext('2d', { willReadFrequently: true });
    }

    componentDidMount() {
        if (!hasGetUserMedia()) return;
        QRScanner.mountedInstances.push(this);
        if (!this.state.hasUserMedia && !QRScanner.userMediaRequested) {
            this.requestUserMedia();
        }
        QRScanner.scanTimer = setInterval(() => {
            this.scanBarcode().then(() => console.log('Scan called'));
        }, SCAN_PERIOD_MS);
    }

    componentWillUnmount() {
        clearInterval(QRScanner.scanTimer);
        const index = QRScanner.mountedInstances.indexOf(this);
        QRScanner.mountedInstances.splice(index, 1);
        QRScanner.userMediaRequested = false;
        if (QRScanner.mountedInstances.length === 0 && this.state.hasUserMedia) {
            if (this.stream.getVideoTracks && this.stream.getAudioTracks) {
                this.stream.getVideoTracks().map(track => track.stop());
            } else {
                this.stream.stop();
            }
            window.URL.revokeObjectURL(this.state.src);
        }
    }

    scanBarcode = async () => {
        let cv = this.canvas;
        let ctx = this.canvasCtx;
        ctx.drawImage(this.video, 0, 0, this.props.width, this.props.height);
        let data = ctx.getImageData(0, 0, cv.width, cv.height);
        const symbols = await scanImageData(data);
        for (let i = 0; i < symbols.length; ++i) {
            const sym = symbols[i];
            this.props.onScan(sym.decode())
        }
    }

    sourceSelected = (videoConstraints) => {
        const constraints = {
            video: videoConstraints || true,
        };
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
                QRScanner.mountedInstances.forEach(instance =>
                    instance.handleUserMedia(null, stream),
                );
            })
            .catch((e) => {
                QRScanner.mountedInstances.forEach(instance =>
                    instance.handleUserMedia(e),
                );
            });
    };

    constraintToSourceId = (constraint) => {
        const deviceId = (constraint || {}).deviceId;
        if (typeof deviceId === 'string') {
            return deviceId;
        }
        if (Array.isArray(deviceId) && deviceId.length > 0) {
            return deviceId[0];
        }
        if (typeof deviceId === 'object' && deviceId.ideal) {
            return deviceId.ideal;
        }
        return null;
    };

    requestUserMedia() {
        navigator.getUserMedia =
            navigator.mediaDevices.getUserMedia ||
            navigator['webkitGetUserMedia'] ||
            navigator['mozGetUserMedia'] ||
            navigator['msGetUserMedia'];

        if ('mediaDevices' in navigator) {
            this.sourceSelected(this.props.videoConstraints);
        } else {
            const optionalSource = id => ({optional: [{sourceId: id}]});
            MediaStreamTrack.getSources((sources) => {
                let videoSource = null;
                sources.forEach((source) => {
                    if (source.kind === 'video') {
                        videoSource = source.id;
                    }
                });
                const videoSourceId = this.constraintToSourceId(this.props.videoConstraints);
                if (videoSourceId) {
                    videoSource = videoSourceId;
                }
                this.sourceSelected(optionalSource(videoSource));
            });
        }
        QRScanner.userMediaRequested = true;
    }

    handleUserMedia(err, stream) {
        if (err) {
            this.setState({hasUserMedia: false});
            this.props.onError(err);
            return;
        }
        this.stream = stream;
        try {
            this.video.srcObject = stream;
            this.setState({hasUserMedia: true});
        } catch (error) {
            this.setState({
                hasUserMedia: true,
                src: window.URL.createObjectURL(stream),
            });
        }
    }

    render() {
        return (
            <div id='videoview'>
                <video
                    autoPlay
                    width={"100%"}
                    src={this.state.src}
                    className={this.props.className}
                    playsInline
                    style={this.props.style}
                    ref={(ref) => {
                        this.video = ref;
                    }}
                />
                <canvas id="overlay" width={this.props.width} height={this.props.height}/>
            </div>
        );
    }
}