import React, {useEffect, useState} from "react";
import {verifyCredentials} from "../../utils/verify-service";
import {Loader} from "../Loader";
import CertificateValidImg from "../../assets/img/certificate-valid.svg";
import CertificateInValidImg from "../../assets/img/certificate-invalid.svg";
import {CustomButton} from "../CustomButton";
import "./index.css";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { format } from 'date-fns'

export const CertificateStatus = ({certificateData, goBack}) => {
    const [isLoading, setLoading] = useState(true);
    const [isValid, setValid] = useState(false);

    useEffect(() => {
        async function verify() {
            try {
                const verificationResponse = await verifyCredentials(certificateData);
                if (verificationResponse.verified) {
                    setValid(true)
                }
            } catch (e) {
                setValid(false)
            } finally {
                setLoading(false)
            }
        }
        verify().then(() => console.log('Verification is called'))
    }, [certificateData]);

    function formatIssueDate(issuedDate) {
        let date = new Date(issuedDate);
        return format(date, 'dd/MM/yyyy');
    }

    return (
        isLoading ? <Loader/> : <div className="certificate-status-wrapper">
            <img src={isValid ? CertificateValidImg : CertificateInValidImg} alt={""}
                 className="certificate-status-image"/>
            <h3 className="certificate-status">
                {
                    isValid ? "Successfully Verified" : "Invalid"
                }
            </h3>
            <br/>
            {
                isValid &&
                <>
                    <Card style={{ width: '18rem' }} border="success">
                        <Card.Body>
                            <Card.Title>Credential Note</Card.Title>
                            <Card.Text>
                                {certificateData.credentialSubject['note']}
                            </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                                Issued at: {formatIssueDate(certificateData.issuanceDate)}
                            </ListGroup.Item>
                            <ListGroup.Item>From: {certificateData.credentialSubject['ownerFAN']}</ListGroup.Item>
                            <ListGroup.Item>To: {certificateData.credentialSubject['TIN']}</ListGroup.Item>
                        </ListGroup>
                    </Card>
                </>
            }
            {
                !isValid &&
                <>
                    <Card style={{ width: '18rem' }} border="danger">
                        <Card.Body>
                            <Card.Title>Credential Note</Card.Title>
                            <Card.Text>
                                The given document is not issued from TAXID
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </>
            }
            <br/>
            <CustomButton className="blue-btn m-3" onClick={goBack}>Verify Another Credential</CustomButton>
        </div>
    )
}